import React from "react";
import Footer from "../../components/footer/footer";
import faq from "./faq.json";
import CustomContainer from "../../components/general/contianer"
import SEO from "../../components/seo/seo"
import FAQ from "../../page-components/faq/faq.json"
import { Disclosure } from "@headlessui/react"
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown"

const FAQIndex = ({transitionStatus}) => {

    return (
        <>
            <SEO
                description={""}
                title={"FAQ"}
                pathname={"/faq"}
            />
            <CustomContainer>
                <h1 className={"mt-40 text-gray-800 font-bold font-serif text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl text-center px-4"}>
                    FAQ
                </h1>
                <div style={{ margin: "80px 0", boxSizing: "border-box" }}>
                    <div className={"grid grid-cols-1 sm:grid-cols-2"}>
                        <div>
                            <h3 className={"text-gray-800 font-semibold font-sans text-2xl text-center"}>
                                Products & Website
                            </h3>
                        </div>
                        <div className={"flex flex-col space-y-3"}>
                            {
                                faq.filter((el) => el.category === "product").map((q) => {
                                    return (
                                        <Disclosure>
                                            {({ open }) => (
                                                <>
                                                    <Disclosure.Button className={`flex justify-between w-full px-4 py-3 text-base font-sans font-regular text-left text-gray-900 bg-background-dark rounded-lg ${ open ? "shadow-inner" : "" } transition duration-200 hover:shadow-inner  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}>
                                                        <span >{q.question}</span>
                                                        <FiChevronDown
                                                            className={`${
                                                                open ? 'transform rotate-180' : ''
                                                            } w-5 h-5 text-gray-500`}
                                                        />
                                                    </Disclosure.Button>
                                                    <Disclosure.Panel className="font-sans font-regular text-base px-4 pt-0 border-b pb-4 text-sm text-gray-800">
                                                        {q.answer}
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                    )
                                })

                            }
                        </div>
                    </div>
                </div>
                <div style={{ margin: "80px 0", boxSizing: "border-box" }}>
                    <div className={"grid grid-cols-1 sm:grid-cols-2"}>
                        <div>
                            <h3 className={"text-gray-800 font-semibold font-sans text-2xl text-center"}>
                                Orders & Shipping
                            </h3>
                        </div>
                        <div className={"flex flex-col space-y-3"}>
                            {
                                faq.filter((el) => el.category === "shipping").map((q) => {
                                    return (
                                        <Disclosure>
                                            {({ open }) => (
                                                <>
                                                    <Disclosure.Button className={`flex justify-between w-full px-4 py-3 text-base font-sans font-regular text-left text-gray-900 bg-background-dark rounded-lg ${ open ? "shadow-inner" : "" } transition duration-200 hover:shadow-inner  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}>
                                                        <span >{q.question}</span>
                                                        <FiChevronDown
                                                            className={`${
                                                                open ? 'transform rotate-180' : ''
                                                            } w-5 h-5 text-gray-500`}
                                                        />
                                                    </Disclosure.Button>
                                                    <Disclosure.Panel className="font-sans font-regular text-base px-4 pt-0 border-b pb-4 text-sm text-gray-800">
                                                        {q.answer}
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                    )
                                })

                            }
                        </div>
                    </div>
                </div>
                <div style={{ margin: "80px 0", boxSizing: "border-box" }}>
                    <div className={"grid grid-cols-1 sm:grid-cols-2"}>
                        <div>
                            <h3 className={"text-gray-800 font-semibold font-sans text-2xl text-center"}>
                                Groomd Men's Club
                            </h3>
                        </div>
                        <div className={"flex flex-col space-y-3"}>
                            {
                                faq.filter((el) => el.category === "club").map((q) => {
                                    return (
                                        <Disclosure>
                                            {({ open }) => (
                                                <>
                                                    <Disclosure.Button className={`flex justify-between w-full px-4 py-3 text-base font-sans font-regular text-left text-gray-900 bg-background-dark rounded-lg ${ open ? "shadow-inner" : "" } transition duration-200 hover:shadow-inner  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}>
                                                        <span >{q.question}</span>
                                                        <FiChevronDown
                                                            className={`${
                                                                open ? 'transform rotate-180' : ''
                                                            } w-5 h-5 text-gray-500`}
                                                        />
                                                    </Disclosure.Button>
                                                    <Disclosure.Panel className="font-sans font-regular text-base px-4 pt-0 border-b pb-4 text-sm text-gray-800">
                                                        {q.answer}
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                    )
                                })

                            }
                        </div>
                    </div>
                </div>
                {/*<div style={{ margin: "120px 0", display: "flex", justifyContent: "center" }}>*/}
                {/*    <div style={{ maxWidth: "850px", background: "rgba(247, 231, 228, 0.6)", textAlign: "center", borderRadius: "8px", padding: "32px 32px" }}>*/}
                {/*        <Text variant={'body1'} style={{ color: "black" }}>*/}
                {/*            If you can't find the answer you are looking for, please email us!*/}
                {/*        </Text>*/}
                {/*        <div style={{  height: "32px" }}/>*/}
                {/*        <SecondaryButton disableArrowAnimation style={{ height: "48px", width: "220px"}} to={"mailto:care@groomd.co.in"} >*/}
                {/*            Send Email*/}
                {/*        </SecondaryButton>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </CustomContainer>
            <Footer transitionStatus={transitionStatus}/>
        </>
    )
}

export default FAQIndex;